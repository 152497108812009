import React, { useState, useEffect, useCallback } from "react";
import api from "../../../services/api";
import SubHeader from "../SubHeader";
import { Dropdown } from "react-bootstrap";
import Filter from "../../../components/Filter";
import { saveAs } from "file-saver";
import { getColor, getRandomColor } from "../../../utils/globalFunctions";
import "chartjs-plugin-datalabels";
import Graph from "./Graph";
import ReactLoading from "react-loading";
import List from "./List";

function Comissao(props) {
  const [graph, setGraph] = useState({
    seguradoras: [],
    ramos: [],
  });

  const [datasets, setDatasets] = useState({
    seguradoras: [],
    ramos: [],
  });

  const [responseData, setResponseData] = useState({});

  const [graphType, setGraphType] = useState({
    seguradoras: {
      premio: true,
      cont: false,
    },
    ramos: {
      premio: true,
      cont: false,
    },
  });

  const [isExporting, setIsExporting] = useState(false);

  const graphTypeHandler = useCallback((key, param1, param2, checked) => {
    setGraphType((prevState) => {
      const newState = { ...prevState };
      newState[key][param1] = checked;
      newState[key][param2] = !checked;
      return newState;
    });
  }, []);

  const exportToExcel = useCallback(async () => {
    setIsExporting(true);
    const response = await api.get("reports/comissao?", {
      params: { ...props.match.params, isExport: true },
      responseType: "arraybuffer",
    });
    const file = new Blob([response.data], { type: "application/excel" });
    saveAs(file, "Relatório de Comissões Ribercred Seguros.xlsx");
    setIsExporting(false);
  }, [props.match.params]);

  useEffect(() => {
    setDatasets({
      seguradoras: graph.seguradoras.map((item) => ({
        value: item[graphType.seguradoras.premio ? "sum" : "count"],
        label: item.label,
        checked: item.checked,
        color: item.color,
      })),
      ramos: graph.ramos.map((item) => ({
        value: item[graphType.ramos.premio ? "sum" : "count"],
        label: item.label,
        checked: item.checked,
        color: item.color,
      })),
    });
  }, [graphType, graph]);

  useEffect(() => {
    const loadReport = async () => {
      if (responseData.seguradoras) {
        const seguradoras = responseData?.seguradoras?.map((item) => ({
          label: item.NOME.replace(/\//g, ".").replace("�", ""),
          sum: item.soma,
          count: item.cont,
          color: getColor(getRandomColor(), 0.8),
          checked: true,
        }));
        const ramos = responseData?.ramos?.map((item) => ({
          label: item.NOME.replace(/\//g, ".").replace("�", ""),
          sum: item.soma,
          count: item.cont,
          color: getColor(getRandomColor(), 0.8),
          checked: true,
        }));
        setGraph({ seguradoras, ramos });
      }
    };
    loadReport();
  }, [responseData]);
  return (
    <>
      <SubHeader
        title="Relatório de Comissões"
        icon={<i className="far fa-money-bill-alt"></i>}
      />
      <div className="mr-8 ml-8">
        <div className="row  mb-8">
          <div className="col">
            <div className="card ">
              <div className="card-header">
                <h3 className="card-label text-dark">Filtros</h3>
              </div>
              <div className="card-body">
                <div className="row mb-8">
                  <div className="col">
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="light"
                        id=""
                        className={`btn-block`}
                      >
                        <span>Data Entrada</span>
                        &nbsp;
                        <i className="fa fa-sort" />
                        <i className="fa fa-filter" />
                      </Dropdown.Toggle>
                      <Filter
                        col={{ filter: "date", key: "data_entrada_operacao" }}
                        {...props}
                      />
                    </Dropdown>
                  </div>
                  <div className="col">
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="light"
                        id=""
                        className={`btn-block`}
                      >
                        <span>Parceiro</span>
                        &nbsp;
                        <i className="fa fa-sort" />
                        <i className="fa fa-filter" />
                      </Dropdown.Toggle>
                      <Filter
                        col={{ filter: "text", key: "nome_loja" }}
                        {...props}
                      />
                    </Dropdown>
                  </div>
                  <div className="col">
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="light"
                        id=""
                        className={`btn-block`}
                      >
                        <span>Nome do Cliente</span>
                        &nbsp;
                        <i className="fa fa-sort" />
                        <i className="fa fa-filter" />
                      </Dropdown.Toggle>
                      <Filter
                        col={{ filter: "text", key: "nome_cliente" }}
                        {...props}
                      />
                    </Dropdown>
                  </div>
                  <div className="col">
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="light"
                        id=""
                        className={`btn-block`}
                      >
                        <span>CPF/CNPJ</span>
                        &nbsp;
                        <i className="fa fa-sort" />
                        <i className="fa fa-filter" />
                      </Dropdown.Toggle>
                      <Filter
                        col={{ filter: "text", key: "cpf_cnpj" }}
                        {...props}
                      />
                    </Dropdown>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="light"
                        id=""
                        className={`btn-block`}
                      >
                        <span>Ramo de Seguro</span>
                        &nbsp;
                        <i className="fa fa-sort" />
                        <i className="fa fa-filter" />
                      </Dropdown.Toggle>
                      <Filter
                        col={{ filter: "text", key: "convenio" }}
                        {...props}
                      />
                    </Dropdown>
                  </div>
                  <div className="col">
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="light"
                        id=""
                        className={`btn-block`}
                      >
                        <span>Seguradora</span>
                        &nbsp;
                        <i className="fa fa-sort" />
                        <i className="fa fa-filter" />
                      </Dropdown.Toggle>
                      <Filter
                        col={{ filter: "text", key: "nome_instituicao" }}
                        {...props}
                      />
                    </Dropdown>
                  </div>
                  <div className="col">
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="light"
                        id=""
                        className={`btn-block`}
                      >
                        <span>Tipo de operação</span>
                        &nbsp;
                        <i className="fa fa-sort" />
                        <i className="fa fa-filter" />
                      </Dropdown.Toggle>
                      <Filter
                        col={{ filter: "text", key: "tipo_operacao" }}
                        {...props}
                      />
                    </Dropdown>
                  </div>
                  <div className="col">
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="light"
                        id=""
                        className={`btn-block`}
                      >
                        <span>Data Pagamento</span>
                        &nbsp;
                        <i className="fa fa-sort" />
                        <i className="fa fa-filter" />
                      </Dropdown.Toggle>
                      <Filter
                        col={{ filter: "date", key: "data_pgto_comissao" }}
                        {...props}
                      />
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row equal mb-8">
          <div className="col">
            <div className="card " style={{ height: "100%" }}>
              <div className="card-header d-flex justify-content-between">
                <h3 className="card-label text-dark">Seguradoras</h3>
              </div>
              <Graph
                graphKey="seguradoras"
                datasets={datasets}
                graph={graph}
                setGraph={setGraph}
                graphType={graphType}
                setGraphType={setGraphType}
                graphTypeHandler={graphTypeHandler}
              />
            </div>
          </div>
          <div className="col">
            <div className="card " style={{ height: "100%" }}>
              <div className="card-header d-flex justify-content-between">
                <h3 className="card-label text-dark">Ramos de Seguro</h3>
              </div>
              <Graph
                graphKey="ramos"
                datasets={datasets}
                graph={graph}
                setGraph={setGraph}
                graphType={graphType}
                setGraphType={setGraphType}
                graphTypeHandler={graphTypeHandler}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="card ">
              <div className="card-header d-flex justify-content-between">
                <h3 className="card-label text-dark">
                  Acompanhamento analítico
                </h3>
                <div>
                  {props.match &&
                  props.match.params &&
                  props.match.params.query &&
                  props.match.params.query !== "{}" ? (
                    <button
                      className="btn btn-warning btn-sm mr-2"
                      onClick={() => {
                        props.history.push(
                          props.match.path
                            .replace(":page", "1")
                            .replace(":limit", props.match.params.limit)
                            .replace(":sort", props.match.params.sort)
                            .replace(":query", "{}")
                        );
                      }}
                    >
                      Limpar Filtro
                    </button>
                  ) : null}
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={exportToExcel}
                    disabled={isExporting}
                  >
                    {isExporting ? (
                      <>
                        <i>
                          <ReactLoading
                            type="spin"
                            color="#ffffff"
                            height={18}
                            width={18}
                          />
                        </i>{" "}
                        Aguarde
                      </>
                    ) : (
                      <>
                        Exportar <i className=" far fa-file-excel"></i>
                      </>
                    )}
                  </button>
                </div>
              </div>
              <div className="card-body pt-1">
                <div className="row">
                  <div className="col">
                    {
                      <List
                        {...props}
                        populateOther={true}
                        setOther={setResponseData}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Comissao;
