import React, { useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import userProfileContext from "../../../contexts/userProfileContext";

function Aside({ aSide, location }) {
  const profile = useContext(userProfileContext);

  return (
    <div
      id="kt_aside"
      className={`aside aside-left d-flex flex-column flex-row-auto ${
        aSide && "aside-on"
      }`}
    >
      <div className={`brand flex-column-auto `} id="kt_brand">
        <Link to="" className="brand-logo mt-10 mb-10">
          <img
            alt="logo"
            style={{ width: "90%" }}
            src="https://zenbytes-public.sfo2.digitaloceanspaces.com/ribercred/logo-riber.png"
          />
        </Link>
      </div>
      <div
        id="kt_aside_menu_wrapper"
        className="aside-menu-wrapper flex-column-fluid"
      >
        <div id="kt_aside_menu" data-menu-vertical="1" className={`aside-menu`}>
          <ul className={`menu-nav`}>
            <li
              className={`menu-item ${
                location.pathname === "/" || location.pathname === "/indicacao"
                  ? "active"
                  : ""
              }`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/indicacao">
                <span className="menu-icon">
                  <i className="far fa-hand-point-right"></i>
                </span>
                <span className="menu-text">Indicação de Seguros</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${
                location.pathname.split("/")[1] === "indicacoes" ? "active" : ""
              }`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/indicacoes/1/20/-numero/{}">
                <span className="menu-icon">
                  <i className="far fa-chart-bar"></i>
                </span>
                <span className="menu-text">Acompanha suas Indicações</span>
              </NavLink>
            </li>
            {profile.roles?.find(
              (item) =>
                item === "comissoes" ||
                item === "Zenbytes" ||
                item === "superAdmin"
            ) && (
              <li
                className={`menu-item ${
                  location.pathname.split("/")[1] === "carteira" ? "active" : ""
                }`}
                aria-haspopup="true"
              >
                <NavLink
                  className="menu-link"
                  to="/carteira/1/20/-apolice.vigencia/{}"
                >
                  <span className="menu-icon">
                    <i className="far fa-id-card"></i>
                  </span>
                  <span className="menu-text">Acompanhe sua Carteira</span>
                </NavLink>
              </li>
            )}

            {profile.roles?.find(
              (item) =>
                item === "comissoes" ||
                item === "Zenbytes" ||
                item === "superAdmin"
            ) && (
              <li
                className={`menu-item ${
                  location.pathname.split("/")[1] === "comissao" ? "active" : ""
                }`}
                aria-haspopup="true"
              >
                <NavLink
                  className="menu-link"
                  to="/comissao/1/20/-data_entrada_operacao/{}"
                >
                  <span className="menu-icon">
                    <i className="far fa-money-bill-alt"></i>
                  </span>
                  <span className="menu-text">Relatório de Comissões</span>
                </NavLink>
              </li>
            )}
          </ul>
          <ul className={`menu-nav`}>
            <li
              className={`menu-item invert ${
                location.pathname.split("/")[1] === "comissao" ? "active" : ""
              }`}
              aria-haspopup="true"
            >
              <a
                className="menu-link"
                href="https://wa.me/5516997287209"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="menu-icon">
                  <i className="fab fa-whatsapp"></i>
                </span>
                <span className="menu-text">Entre em contato</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Aside;
