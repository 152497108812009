import React, { useContext, useState, useEffect } from "react";
import UserProfileContext from "../../../../contexts/userProfileContext";
import SubHeader from "../../SubHeader";
import { cpfMask } from "../../../../utils/globalFunctions";
import Input from "../../../../components/Input";
import Swal from "sweetalert2";
import api from "../../../../services/api";

const Profile = (props) => {
  const profile = useContext(UserProfileContext);

  const [userData, setUserData] = useState({
    nome: "",
    username: "",
    cpf: "",
    cargo: "",
  });

  const editUserData = async (e) => {
    e.preventDefault();
    const requestUserData = { ...userData };
    const response = await Swal.fire({
      title: "Editar Dados",
      text: "Deseja confirmar edição dos dados",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0451e8",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, Editar Dados!",
      showLoaderOnConfirm: true,
      preConfirm: async () =>
        await api.post("auth/userprofile", requestUserData).catch((err) => ({
          err: true,
          data: { message: err.response.data.message },
        })),
    });

    if (response.value) {
      Swal.fire({
        title: "Editar Dados",
        text: response.value.data.message,
        icon: response.value.err ? "error" : "success",
        preConfirm: () =>
          !response.value.err ? window.location.reload() : null,
      });
    }
  };

  useEffect(() => {
    const loadUserData = () => {
      setUserData({ ...profile });
    };
    if (profile._id) {
      loadUserData();
    }
  }, [profile]);

  return (
    <>
      <SubHeader
        title="Meu Dados"
        icon={<i className="far fa-user"></i>}
        {...props}
      />
      <form onSubmit={editUserData}>
        <div className="mr-8 ml-8 ">
          <div className="card ">
            <div className="card-header">
              <h3 className="card-label text-dark">Meus Dados</h3>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <Input
                    item={userData}
                    setItem={setUserData}
                    params="nome"
                    label="Nome"
                    type="input"
                    capitalize
                    required={true}
                  />
                </div>
                <div className="col-md-6">
                  <Input
                    item={userData}
                    setItem={setUserData}
                    label="Email"
                    params="username"
                    type="input"
                    required={true}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Input
                    item={userData}
                    setItem={setUserData}
                    params="cargo"
                    label="Cargo"
                    type="input"
                    capitalize
                    required={true}
                  />
                </div>
                <div className="col-md-6">
                  <Input
                    item={userData}
                    setItem={setUserData}
                    label="CPF"
                    params="cpf"
                    value={cpfMask(userData.cpf)}
                    disabled={true}
                    type="input"
                  />
                </div>
              </div>
            </div>
            <div className="card-footer">
              <div className="row">
                <div className="col text-center">
                  <button type="submit" className="btn btn-primary">
                    Salvar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default Profile;
