import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Login from "./pages/Auth/Login";
import Forgot from "./pages/Auth/Forgot";
import NewPassword from "./pages/Auth/NewPassword";
import ReactLoading from "react-loading";
import Application from "./pages/App";
import { PrivacityPolicy } from "./pages/PrivacityPolicy";
import { TermsConditions } from "./pages/TermsConditions";
import { IndicacaoSeguro } from "./pages/IndicacaoSeguro";
import { ConfirmEmail } from "./pages/Auth/ConfirmEmail";

function App() {
  const [loading, setLoading] = useState(true);

  const [showSpin, setShowSpin] = useState(true);
  let stateCheck = setInterval(() => {
    if (document.readyState === "complete") {
      clearInterval(stateCheck);
      setLoading(false);
      setTimeout(() => {
        setShowSpin(false);
      }, 400);
      // document ready
    }
  }, 1000);
  return (
    <>
      <div className={`loading-page ${!loading ? "hide-loading" : ""}`}>
        {showSpin && (
          <>
            <img
              src="https://zenbytes-public.sfo2.digitaloceanspaces.com/ribercred/logo-riber-negativo.png"
              alt="logo"
            />
            <ReactLoading
              type="spin"
              color="#ffffff"
              height={150}
              width={150}
              className="spin"
            />
          </>
        )}
      </div>
      <Router>
        <Switch>
          <Route
            path="/new-password/:token"
            render={(props) => <NewPassword {...props} />}
          />
          <Route
            path="/privacity-policy"
            render={(props) => <PrivacityPolicy />}
          />
          <Route
            path="/terms-conditions"
            render={(props) => <TermsConditions />}
          />
          <Route
            path="/indicacao-seguro/:id"
            render={(props) => <IndicacaoSeguro {...props} />}
          />
          <Route path="/forgot" render={(props) => <Forgot {...props} />} />
          <Route path="/login" render={(props) => <Login {...props} />} />
          <Route
            path="/confirm-email/:token/:url"
            render={(props) => <ConfirmEmail {...props} />}
          />
          <Route path="/" render={(props) => <Application {...props} />} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
