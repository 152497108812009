import React from "react";
import Input from "../../../../components/Input";
import StringMask from "string-mask";
import { Pie } from "react-chartjs-2";

function Graph({
  graphKey,
  datasets,
  graph,
  setGraph,
  graphType,
  setGraphType,
  graphTypeHandler,
}) {
  return (
    <div className="card-body pt-1">
      <div className="row">
        <div className="col">
          <Input
            item={graphType}
            setItem={setGraphType}
            params={`${graphKey}.premio`}
            onChange={(e) =>
              graphTypeHandler(graphKey, "premio", "cont", e.target.checked)
            }
            type="switch"
            label="Prêmio"
          />

          <Input
            item={graphType}
            setItem={setGraphType}
            params={`${graphKey}.cont`}
            onChange={(e) =>
              graphTypeHandler(graphKey, "cont", "premio", e.target.checked)
            }
            type="switch"
            label="Quantidade"
          />
        </div>
      </div>

      {graph[graphKey][0] && (
        <Pie
          data={{
            labels: datasets[graphKey]
              .filter((item) => item.checked)
              .map((item) => item.label),
            datasets: [
              {
                data: datasets[graphKey]
                  .filter((item) => item.checked)
                  .map((item) => item.value),
                backgroundColor: datasets[graphKey]
                  .filter((item) => item.checked)
                  .map((item) => item.color),
              },
            ],
          }}
          options={{
            tooltips: {
              callbacks: {
                label: function (tooltipItem, data) {
                  return (
                    data.labels[tooltipItem.index] +
                    " | " +
                    (
                      (data.datasets[0].data[tooltipItem.index] /
                        data.datasets[0].data.reduce((a, b) => a + b)) *
                      100
                    ).toFixed(0) +
                    "%"
                  );
                },
              },
            },
            legend: {
              display: false,
              position: "left",
              labels: {
                boxWidth: 20,
              },
            },
            plugins: {
              datalabels: {
                display: "auto",
                color: "white",
                offset: 50,
                align: "start",
                anchor: "end",
                start: "end",
                formatter: (value, context) => {
                  return (
                    (
                      (value / context.dataset.data.reduce((a, b) => a + b)) *
                      100
                    ).toFixed(0) + "%"
                  );
                },
              },
            },
          }}
          height={300}
        />
      )}
      <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded">
        <table className="kt-datatable__table table-hover">
          <thead className="kt-datatable__head">
            <tr className="kt-datatable__row text-left">
              <th
                className="kt-datatable__cell kt-datatable__cell--sort"
                style={{ width: 65 }}
              ></th>
              <th className="kt-datatable__cell kt-datatable__cell--sort">
                Quantidade
              </th>
              <th className="kt-datatable__cell kt-datatable__cell--sort">
                Seguradora
              </th>
              <th className="kt-datatable__cell kt-datatable__cell--sort">%</th>
              <th className="kt-datatable__cell kt-datatable__cell--sort">$</th>
            </tr>
            {graph[graphKey]
              .map((item, index) => ({ ...item, index }))
              .sort((a, b) => {
                const key = graphType[graphKey].premio ? "sum" : "count";
                return b[key] - a[key];
              })
              .map((item, index) => (
                <tr
                  className={`kt-datatable__row ${
                    !item.checked ? "kt-datatable__row--striped" : ""
                  }`}
                  key={item.label + index}
                >
                  <td
                    className="pl-6 pt-2"
                    style={{ backgroundColor: item.color }}
                  >
                    <Input
                      type="switch"
                      item={graph}
                      setItem={setGraph}
                      params={`${graphKey}.${item.index}.checked`}
                    />
                  </td>
                  <td className="pl-4">
                    <span>{item.count}</span>
                  </td>
                  <td className="pl-4">
                    <span>{item.label}</span>
                  </td>
                  <td className="pl-4">
                    <span>
                      {(
                        (item[graphType[graphKey].premio ? "sum" : "count"] /
                          graph[graphKey]
                            .map(
                              (item) =>
                                item[
                                  graphType[graphKey].premio ? "sum" : "count"
                                ]
                            )
                            .reduce((a, b) => a + b)) *
                        100
                      ).toFixed(0) + "%"}
                    </span>
                  </td>
                  <td className="pl-4">
                    <span>
                      R${" "}
                      {new StringMask("#.##0,00", {
                        reverse: true,
                      }).apply(item.sum.toFixed(2).replace(".", ""))}
                    </span>
                  </td>
                </tr>
              ))}
            {graph[graphKey][0] && (
              <tr className={`kt-datatable__row `}>
                <td className="kt-datatable__cell">
                  <span>
                    <b>Total</b>
                  </span>
                </td>
                <td className="kt-datatable__cell">
                  <span>
                    <b>
                      {graph[graphKey]
                        .map((item) => item.count)
                        .reduce((a, b) => a + b)}
                    </b>
                  </span>
                </td>
                <td className="kt-datatable__cell">
                  <span>
                    <b></b>
                  </span>
                </td>
                <td className="kt-datatable__cell">
                  <span>
                    <b></b>
                  </span>
                </td>
                <td className="kt-datatable__cell">
                  <span>
                    <b>
                      R${" "}
                      {new StringMask("#.##0,00", {
                        reverse: true,
                      }).apply(
                        graph[graphKey]
                          .map((item) => item.sum)
                          .reduce((a, b) => a + b)
                          .toFixed(2)
                          .replace(".", "")
                      )}
                    </b>
                  </span>
                </td>
              </tr>
            )}
          </thead>
        </table>
      </div>
    </div>
  );
}

export default Graph;
