import React from "react";

const TotalItems = props => {
  return (
    <span className="kt-datatable__pager-detail">
      Mostrando{" "}
      {+props.page * +props.limit -
        +props.limit -
        1}{" "}
      a{" "}
      {props.total > +props.page * +props.limit
        ? +props.page * +props.limit
        : props.total}{" "}
      de {props.total}
    </span>
  );
};

export default TotalItems;
