import React, { useState } from "react";
import Table from "../../../../components/Table";
import ModalIndicacao from "../ModalIndicacao";
import api from "../../../../services/api";

function List(props) {
  const [filter, setFilter] = useState({
    status: [],
    ramoSeguro: [],
    parceiros: [],
  });
  const [modalShow, setModalShow] = useState(false);
  const [indicacao, setIndicacao] = useState(null);
  const tableParams = [
    {
      key: "status",
      label: "Status",
      params: "texto",
      filter: "checklist",
      checklistData: filter.status
        .filter((item) => !!item)
        .map((item) => ({
          label: item.texto,
          key: "status.key",
          id: item.key,
        })),
      filterKey: "status.key",
      type: "custom",
      response: (row) => (
        <span
          className={`btn btn-sm btn-block btn-status ${
            row.status ? row.status.key : ""
          }`}
          style={{ whiteSpace: "nowrap" }}
        >
          {row.status
            ? row.status.texto === "Aguardando"
              ? "Análise Seguradora"
              : row.status.texto
            : null}
        </span>
      ),
    },
    {
      key: "numero",
      label: "Nº",
      width: 250,
      filter: "number",
      placeholder: "Digite o Número",
    },
    {
      key: "data",
      label: "Data Indicação",
      filter: "date",
      type: "date",
    },
    {
      key: "ramoSeguro",
      params: "nome",
      label: "Ramo Seguro",
      filter: "checklist",
      checklistData: filter.ramoSeguro
        .filter((item) => !!item)
        .map((item) => ({
          label: item.nome,
          key: "ramoSeguro",
          id: item._id,
        })),
      filterKey: "ramoSeguro",
      type: "custom",
      response: (row) => (
        <div className="ramos__item" style={{ whiteSpace: "nowrap" }}>
          <img
            src={`https://ribercred.zw20.zenbytes.com.br/img/upload/icone-ramo-seguro-${row.ramoSeguro._id}.png`}
            alt={row.ramoSeguro.nome}
          ></img>{" "}
          <span>{row.ramoSeguro.nome}</span>
        </div>
      ),
    },
    {
      key: "nome",
      label: "Nome",
      filter: "text",
      type: "capitalize",
      placeholder: "Digite o nome",
    },
    {
      key: "tipoSolicitacao",
      label: "TS",
      filter: "text",
      type: "custom",
      response: ({ tipoSolicitacao }) => {
        if (tipoSolicitacao === "cotacao") {
          return "Cotação";
        } else if (tipoSolicitacao === "agendamento") {
          return "Agendamento";
        } else if (tipoSolicitacao === "endosso") {
          return "Endosso";
        } else {
          return "Renovação";
        }
      },
    },
    {
      key: "usuarioCadastro",
      params: "nome",
      label: "Usuário Parceiro",
      filter: "checklist",
      checklistData: filter.parceiros
        .filter((item) => !!item)
        .map((item) => ({
          label: item.nome,
          key: "usuarioCadastro",
          id: item._id,
        })),
      filterKey: "usuarioCadastro",
      placeholder: "Digite o parceiro",
    },
    {
      key: "parceiro",
      label: "Parceiro",
      filter: "text",
      type: "capitalize",
      placeholder: "Digite o nome",
    },
    {
      key: "ultimoEvento",
      label: "Último Evento",
      filter: "date",
      type: "dateTime",
    },
    {
      key: "efetivacao",
      params: "dataEfetivacao",
      label: "Efetivação",
      filter: "date",
      type: "date",
    },
  ];

  const modalHandler = async (item) => {
    setModalShow(true);
    const response = await api.get(`indicacao/${item._id}`);
    setIndicacao(response.data);
  };
  return (
    <>
      {modalShow ? (
        <ModalIndicacao
          show={modalShow}
          onHide={() => {
            setModalShow(false);
            setIndicacao(null);
          }}
          location={props.location}
          indicacao={indicacao}
        />
      ) : null}
      <Table
        cols={tableParams}
        emptyText={"Nenhuma indicação localizada!"}
        route={"indicacoes"}
        setFilter={setFilter}
        filterRoute={"filters/indicacoes"}
        editHandler={modalHandler}
        noDelete
        {...props}
      />
    </>
  );
}

export default List;
