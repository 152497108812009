import React, { useState, useContext } from "react";
import { Modal } from "react-bootstrap";
import ReactLoading from "react-loading";
import format from "date-fns/format";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import apiOld from "../../../../services/apiOld";
import { saveAs } from "file-saver";
import ProfileContext from "../../../../contexts/userProfileContext";
import Swal from "sweetalert2";
import {
  phoneNumberMask,
  cpfMask,
  getTipoSolicitacao,
} from "../../../../utils/globalFunctions";

function ModalIndicacao(props) {
  const profile = useContext(ProfileContext);

  const [tab, setTab] = useState(0);
  const [loadingProposta, setLoadingProposta] = useState([]);

  const downloadProposta = async (index, proposta) => {
    const loading = [];
    loading[index] = true;
    setLoadingProposta(loading);
    const response = await apiOld
      .post(
        props.indicacao.ramoSeguro.key === "seguroSaude"
          ? "api/createpropostasaude"
          : "api/downloadproposta",
        {
          userId: profile._id,
          proposta: proposta,
          coleta: proposta.coleta,
          ramo: props.indicacao.ramoSeguro.key,
          ts: props.indicacao.tipoSolicitacao,
          cliente: props.indicacao.nome,
          download: true,
        },
        { responseType: "arraybuffer" }
      )
      .catch((err) => ({ message: err.message, err: true }));
    if (response.err) {
      setLoadingProposta([]);
      return Swal.fire("Download proposta", response.message, "error");
    }
    const blobPDF = new Blob([response.data], {
      type: "application/pdf",
    });
    saveAs(
      blobPDF,
      `Proposta Ribercred Seguros - ${proposta.numero} - ${proposta.indicacao.nome}.pdf`
    );
    setLoadingProposta([]);
  };

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter "
    >
      <Modal.Header closeButton>
        <Modal.Title className="contained-modal-title-vcenter text-dark">
          Dados Indicação
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!props.indicacao ? (
          <ReactLoading type="spin" color="#009870" height={30} width={30} />
        ) : (
          <>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label className="form-label text-dark">Número</label>
                  <div className="form-control">{props.indicacao.numero}</div>
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <label className="form-label text-dark">Status</label>
                  <div
                    className={`form-control ${
                      props.indicacao.status ? props.indicacao.status.key : null
                    }`}
                  >
                    {props.indicacao.status
                      ? props.indicacao.status.texto
                      : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label className="form-label text-dark">
                    Usuário Atendimento
                  </label>
                  <div className="form-control">
                    {props.indicacao.usuarioAtendimento
                      ? props.indicacao.usuarioAtendimento.nome
                      : null}
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <label className="form-label text-dark">
                    Usuário Cotação
                  </label>
                  <div className="form-control">
                    {props.indicacao.usuarioCotacao
                      ? props.indicacao.usuarioCotacao.nome
                      : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label className="form-label text-dark">Último evento</label>
                  <div className="form-control" style={{ height: "70px" }}>
                    {props.indicacao.ultimoEvento
                      ? props.indicacao.ultimoEvento.descricao
                      : null}
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <label className="form-label text-dark">
                    Última observação
                  </label>
                  <div className="form-control" style={{ height: "70px" }}>
                    {props.indicacao.ultimoEvento
                      ? props.indicacao.ultimoEvento.obs
                      : null}
                  </div>
                </div>
              </div>
            </div>
            <Tabs
              value={tab}
              indicatorColor="primary"
              textColor="primary"
              onChange={(e, value) => {
                setTab(value);
              }}
            >
              <Tab label="cliente" />
              <Tab label="parceiro" />
              <Tab label="dados" />
              <Tab label="eventos" />
              <Tab label="proposta" />
            </Tabs>
            {tab === 0 && (
              <>
                <div className="row mt-5">
                  <div className="col">
                    <div className="form-group">
                      <label className="form-label text-dark">Nome</label>
                      <div className="form-control">{props.indicacao.nome}</div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      <label className="form-label text-dark">Telefones</label>
                      <div className="form-control">
                        {props.indicacao.telefones
                          .map((item) => phoneNumberMask(item))
                          .reduce((a, b) => a + " | " + b)}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label className="form-label text-dark">Email</label>
                      <div className="form-control">
                        {props.indicacao.email}
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      <label className="form-label text-dark">CPF/CNPJ</label>
                      <div className="form-control">
                        {props.indicacao.documento
                          ? cpfMask(props.indicacao.documento)
                          : null}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {tab === 1 && (
              <>
                <div className="row mt-5">
                  <div className="col">
                    <div className="form-group">
                      <label className="form-label text-dark">Parceiro</label>
                      <div className="form-control">
                        {props.indicacao.usuarioCadastro.parceiro
                          ? props.indicacao.usuarioCadastro.parceiro.nome
                          : null}
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      <label className="form-label text-dark">Loja</label>
                      <div className="form-control">
                        {props.indicacao.usuarioCadastro.loja
                          ? props.indicacao.usuarioCadastro.loja.nome
                          : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label className="form-label text-dark">Nome</label>
                      <div className="form-control">
                        {props.indicacao.usuarioCadastro.nome}
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      <label className="form-label text-dark">Telefones</label>
                      <div className="form-control">
                        {props.indicacao.usuarioCadastro.telefones[0]
                          ? props.indicacao.telefones
                              .map((item) => phoneNumberMask(item))
                              .reduce((a, b) => a + " | " + b)
                          : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label className="form-label text-dark">Email</label>
                      <div className="form-control">
                        {props.indicacao.usuarioCadastro.email}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {tab === 2 && (
              <>
                <div className="row mt-5">
                  <div className="col">
                    <div className="form-group">
                      <label className="form-label text-dark">origem</label>
                      <div className="form-control">
                        {props.indicacao.origem}
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      <label className="form-label text-dark">
                        Data Cadastro
                      </label>
                      <div className="form-control">
                        {format(
                          new Date(props.indicacao.data),
                          "dd/MM/yyyy hh:mm"
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label className="form-label text-dark">Ramo</label>
                      <div className="form-control">
                        <div className="ramos__selected-item">
                          <img
                            src={`https://ribercred.zw20.zenbytes.com.br/img/upload/icone-ramo-seguro-${props.indicacao.ramoSeguro._id}.png`}
                            alt={props.indicacao.ramoSeguro.nome}
                          ></img>{" "}
                          <span>{props.indicacao.ramoSeguro.nome}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      <label className="form-label text-dark">
                        Tipo Solicitação
                      </label>
                      <div className="form-control">
                        {getTipoSolicitacao(props.indicacao)}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {tab === 3 && (
              <div className="row mt-5">
                <div className="col">
                  <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded">
                    <table className="kt-datatable__table table-hover">
                      <thead className="kt-datatable__head">
                        <tr className="kt-datatable__row text-center">
                          <th className="kt-datatable__cell">Data</th>
                          <th className="kt-datatable__cell">Descrição</th>
                          <th className="kt-datatable__cell">observação</th>
                          <th className="kt-datatable__cell">Usuário</th>
                        </tr>
                      </thead>
                      <tbody className="kt-datatable__body">
                        {props.indicacao.eventos.map((item) => (
                          <tr className="kt-datatable__row">
                            <td className="kt-datatable__cell text-center">
                              {format(new Date(item.data), "dd/MM/yyyy hh:mm")}
                            </td>
                            <td className="kt-datatable__cell text-center">
                              {item.descricao}
                            </td>
                            <td className="kt-datatable__cell text-center">
                              {item.obs}
                            </td>
                            <td className="kt-datatable__cell text-center">
                              {item.usuario.nome}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
            {tab === 4 && (
              <div className="row mt-5">
                <div className="col">
                  <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded">
                    <table className="kt-datatable__table table-hover">
                      <thead className="kt-datatable__head">
                        <tr className="kt-datatable__row text-center">
                          <th className="kt-datatable__cell">Data</th>
                          <th className="kt-datatable__cell">Número</th>
                          <th className="kt-datatable__cell">Usuário</th>
                          <th className="kt-datatable__cell">Baixar</th>
                        </tr>
                      </thead>
                      <tbody className="kt-datatable__body">
                        {props.indicacao.propostas.map((item, index) => (
                          <tr className="kt-datatable__row">
                            <td className="kt-datatable__cell text-center">
                              {format(new Date(item.data), "dd/MM/yyyy hh:mm")}
                            </td>
                            <td className="kt-datatable__cell text-center">
                              {item.numero}
                            </td>
                            <td className="kt-datatable__cell text-center">
                              {item.usuarioCadastro.nome}
                            </td>
                            <td className="kt-datatable__cell text-center">
                              <button
                                className="btn btn-sm btn-primary"
                                onClick={() => downloadProposta(index, item)}
                              >
                                {loadingProposta[index] ? (
                                  <ReactLoading
                                    type="spin"
                                    color="#ffffff"
                                    height={19}
                                    width={19}
                                  />
                                ) : (
                                  <i className="far fa-file-pdf"></i>
                                )}
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default ModalIndicacao;
