import React from "react";
import ReactDOM from "react-dom";
import "./assets/plugins/global/plugins.bundle.css";
import "./assets/plugins/global/plugins.bundle.rtl.css";
import "./assets/css/style.bundle.css";
import "./assets/css/kt.datatable.css";
import "./assets/css/kt.nav.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./assets/css/custom.css";

import App from "./App";

ReactDOM.render(<App />, document.getElementById("root"));
