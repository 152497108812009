import React from "react";

export default function index() {
  const today = new Date().getFullYear();
  return (
    <div className={`footer bg-white py-4`} id="kt_footer">
      <div className="text-dark pl-5">
        <span className="text-muted font-weight-bold mr-2">
          {today.toString()}
        </span>{" "}
        &copy; Riber Seguros
      </div>
      <div className="text-dark order-2 order-md-1 pr-5">
        <span className="text-muted font-weight-bold mr-2">
          Desenvolvido por Zenbytes
        </span>{" "}
      </div>
    </div>
  );
}
