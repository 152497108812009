import React, { useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import api from "../../../services/api";
import ReactLoading from "react-loading";
import Input from "../../../components/Input";

const Forgot = (props) => {
  const [form, setForm] = useState({
    username: "",
  });

  const [loading, setLoading] = useState(false);

  const submitData = async (e) => {
    e.preventDefault();
    setLoading(true);
    const response = await api.post("/auth/forgot", form).catch((err) => err);
    setLoading(false);

    if (!response.data) {
      return Swal.fire({
        title: "Esqueci minha senha",
        text: response.message,
        icon: "error",
        heightAuto: false,
      });
    }
    Swal.fire({
      title: "Esqueci minha senha",
      text: "Email de recuparação de senha enviado com sucesso!",
      icon: "success",
      heightAuto: false,
    });
    setForm({
      username: "",
    });
    return props.history.push("/login");
  };

  return (
    <div
      className="d-flex flex-center bgi-size-cover bgi-no-repeat flex-row-fluid"
      style={{
        backgroundImage:
          "url(https://zenbytes-public.sfo2.digitaloceanspaces.com/ribercred/bg-login.jpg)",
      }}
    >
      <div className="login-form text-center text-white p-7 position-relative overflow-hidden">
        <div className="d-flex flex-center mb-10">
          <img
            src="https://zenbytes-public.sfo2.digitaloceanspaces.com/ribercred/logo-riber.png"
            className="max-h-100px"
            alt=""
          />
        </div>

        <div className="login-signin">
          <div className="kt-login__head mb-10">
            <h3 className="kt-login__title">Recuperar Senha</h3>
          </div>
          <form
            className="form fv-plugins-bootstrap fv-plugins-framework"
            id="kt_login_signin_form"
            onSubmit={submitData}
          >
            <div className="form-group fv-plugins-icon-container">
              <Input
                item={form}
                setItem={setForm}
                type="inputOnly"
                className="form-control h-auto text-white placeholder-white opacity-70 bg-dark-o-70 rounded-pill border-0 py-4 px-8 mb-5"
                params="cpf"
                mask="cpfCNPJ"
                placeholder="Digite o CPF"
              />
            </div>

            <div className="form-group text-center mt-10">
              <button
                id="kt_login_signin_submit"
                className="btn btn-block btn-pill btn-outline-white font-weight-bold opacity-90 "
              >
                {loading ? (
                  <ReactLoading
                    type="spin"
                    color="#5aa57f"
                    height={19}
                    width={19}
                  />
                ) : (
                  <span>Enviar</span>
                )}
              </button>
            </div>
            <div></div>
          </form>
          <div className="mt-10">
            <Link
              to="/login"
              id="kt_login_forgot"
              className="text-white font-weight-bold"
            >
              Login
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forgot;
