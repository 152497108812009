import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import api from "../../../services/api";

export const ConfirmEmail = (props) => {
  const [loading, setLoading] = useState(true);

  const [hasError, setHasError] = useState(false);

  const [deviceType, setDeviceType] = useState("");

  useEffect(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
        navigator.userAgent
      )
    ) {
      setDeviceType("Mobile");
    } else {
      setDeviceType("Desktop");
    }
  }, []);

  useEffect(() => {
    const validateToken = async () => {
      const response = await api
        .get("user/validatetoken/" + props.match.params.token)
        .catch((e) => e.message);

      setTimeout(() => {
        if (!response.data) {
          setHasError(true);
        }

        setLoading(false);
      }, 1500);
    };
    return validateToken();
  }, [props.match.params.token]);

  return (
    <div
      className="d-flex flex-center bgi-size-cover bgi-no-repeat flex-row-fluid"
      style={{
        backgroundImage:
          "url(https://zenbytes-public.sfo2.digitaloceanspaces.com/ribercred/bg-login.jpg)",
      }}
    >
      <div className="login-form text-center text-white p-7 position-relative overflow-hidden">
        <div className="d-flex flex-column flex-center mb-15">
          <img
            src="https://zenbytes-public.sfo2.digitaloceanspaces.com/ribercred/logo-riber.png"
            className="max-h-100px"
            alt=""
          />
          <div className="mt-15 d-flex flex-center flex-column ">
            {loading ? (
              <>
                <ReactLoading
                  className="mt10"
                  type="spin"
                  color="#ffba1a"
                  height={75}
                  width={75}
                />
                <h3 className="mt-8">Aguarde, estamos validando seu E-mail</h3>
              </>
            ) : hasError ? (
              <>
                <div
                  className="swal2-icon swal2-error swal2-icon-show d-flex"
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                  }}
                >
                  <span className="swal2-x-mark">
                    <span className="swal2-x-mark-line-left"></span>
                    <span className="swal2-x-mark-line-right"></span>
                  </span>
                </div>
                <h3 className="mt-8">Erro ao validar E-mail!</h3>
              </>
            ) : (
              <>
                <div
                  className="swal2-icon swal2-success swal2-icon-show d-flex"
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                    borderColor: "#5aa57f",
                    color: "#5aa57f",
                  }}
                >
                  <div className="swal2-success-circular-line-left"></div>
                  <span
                    className="swal2-success-line-tip"
                    style={{ backgroundColor: "#5aa57f" }}
                  ></span>
                  <span
                    className="swal2-success-line-long"
                    style={{ backgroundColor: "#5aa57f" }}
                  ></span>
                  <div className="swal2-success-ring"></div>{" "}
                  <div className="swal2-success-fix"></div>
                  <div className="swal2-success-circular-line-right"></div>
                </div>
                <h3 className="mt-8">Seu email foi validado com sucesso!</h3>
              </>
            )}
            {deviceType === "Mobile" ? (
              <a
                href={props.match.params.url}
                className="btn btn-bg-primary mt-10"
                style={{ color: "white" }}
              >
                Voltar para o app
              </a>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
