import React from "react";

function SubHeader({ title, icon, history }) {
  return (
    <div id="kt_subheader" className={`subheader py-2 py-lg-4 subheader-solid`}>
      <div
        className={`container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap`}>
        <div className="d-flex align-items-center flex-wrap mr-1">
          <div className="d-flex align-items-baseline mr-5">
            <h5 className="text-dark font-weight-bold my-2 mr-5">
              {icon} &nbsp;{title}
            </h5>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <button
            className="btn btn-light btn-sm font-weight-bold"
            onClick={() => history.goBack()}
            id="kt_dashboard_daterangepicker">
            <span
              className="text-muted font-weight-bold mr-2"
              id="kt_dashboard_daterangepicker_title">
              <i className="fa fa-arrow-left"></i>
              Voltar
            </span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default SubHeader;
