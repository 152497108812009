import React, { useState, useEffect } from "react";
import SubHeader from "../SubHeader";
import Input from "../../../components/Input";
import api from "../../../services/api";
import { Bar, Pie } from "react-chartjs-2";
import { getColor } from "../../../utils/globalFunctions";
import List from "./List";

function Indicacoes(props) {
  const [grapthType, setGraphType] = useState({
    bar: true,
    pizza: false,
  });

  const [graphData, setGraphData] = useState({});

  const [loading, setLoading] = useState(true);

  const [isRunned, setIsRunned] = useState(false);

  const grapthTypeHandler = (param1, param2, checked) => {
    setGraphType((prevState) => {
      const newState = { ...prevState };
      newState[param1] = checked;
      newState[param2] = !checked;
      return newState;
    });
  };

  useEffect(() => {
    const loadIndicacoes = async () => {
      const response = await api.get(`graphs/indicacoes`);
      const datasets = [];
      const modelData = response.data.graph[0];
      Object.keys(modelData).forEach((key) => {
        if (modelData[key].count !== undefined) {
          datasets.push({
            label: modelData[key].label,
            data: response.data.graph.map((item) => item[key].count),
            borderWidth: 1,
            backgroundColor: getColor(modelData[key].color, 0.2),
            borderColor: getColor(modelData[key].color),
            hoverBackgroundColor: getColor(modelData[key].color, 0.4),
            hoverBorderColor: getColor(modelData[key].color),
          });
        }
      });
      const dataBar = {
        labels: response.data.graph.map((item) => item.mes),
        datasets,
      };

      const dataPie = {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: [],
            hoverBackgroundColor: [],
            borderColor: [],
            borderWidth: 1,
          },
        ],
      };

      Object.keys(modelData).forEach((key) => {
        if (modelData[key].count !== undefined) {
          dataPie.labels.push(modelData[key].label);
          dataPie.datasets[0].data.push(
            response.data.graph
              .map((item) => item[key].count)
              .reduce((a, b) => a + b)
          );
          dataPie.datasets[0].backgroundColor.push(
            getColor(modelData[key].color, 0.2)
          );
          dataPie.datasets[0].hoverBackgroundColor.push(
            getColor(modelData[key].color, 0.4)
          );
          dataPie.datasets[0].borderColor.push(getColor(modelData[key].color));
        }
      });
      setGraphData({ bar: dataBar, pie: dataPie });      
      setLoading(false);
    };
    if(!isRunned){
      setIsRunned(true);
      loadIndicacoes();
    }
    
  }, [props.match.params, isRunned]);

  return loading ? null : (
    <>
      <SubHeader
        title="Acompanhe suas Indicações"
        icon={<i className="far fa-chart-bar"></i>}
        {...props}
      />
      <div className="mr-8 ml-8">
        <div className="row mb-8">
          <div className="col">
            <div className="card ">
              <div className="card-header">
                <h3 className="card-label text-dark">
                  Indicações {grapthType.bar ? "mensais" : "últimos 5 meses"}
                </h3>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <Input
                      item={grapthType}
                      setItem={setGraphType}
                      onChange={(e) =>
                        grapthTypeHandler("bar", "pizza", e.target.checked)
                      }
                      params="bar"
                      type="switch"
                      label="Gráfico de Barras"
                    />

                    <Input
                      item={grapthType}
                      setItem={setGraphType}
                      onChange={(e) =>
                        grapthTypeHandler("pizza", "bar", e.target.checked)
                      }
                      params="pizza"
                      type="switch"
                      label="Gráfico de Pizza"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    {grapthType.bar && (
                      <Bar
                        data={graphData.bar}
                        height={350}
                        options={{
                          maintainAspectRatio: false,
                        }}
                      />
                    )}

                    {grapthType.pizza && (
                      <Pie
                        data={graphData.pie}
                        options={{ legend: { position: "left" } }}
                        height={68}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="card ">
              <div className="card-header d-flex justify-content-between">
                <h3 className="card-label text-dark">Indicações</h3>
                {props.match &&
                props.match.params &&
                props.match.params.query &&
                props.match.params.query !== "{}" ? (
                  <button
                    className="btn btn-warning btn-sm"
                    onClick={() => {
                      props.history.push(
                        props.match.path
                          .replace(":page", "1")
                          .replace(":limit", props.match.params.limit)
                          .replace(":sort", props.match.params.sort)
                          .replace(":query", "{}")
                      );
                    }}>
                    Limpar Filtro
                  </button>
                ) : null}
              </div>
              <div className="card-body pt-1">
                <div className="row">
                  <div className="col">{<List {...props} />}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Indicacoes;
