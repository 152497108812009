import React from "react";
import Table from "../../../../components/Table";
import StringMask from "string-mask";

function List(props) {
  const tableParams = [
    {
      key: "vigencia",
      label: "Vigência",
      filter: "date",
      type: "date",
    },
    {
      key: "nome",
      label: "Nome do Cliente",
      filter: "text",
      type: "capitalize",
    },
    {
      key: "documento",
      label: "CPF-CNPJ",
      filter: "text",
    },
    {
      key: "ramoSeguro",
      label: "Ramo de seguro",
      filter: "text",
      type: "capitalize",
    },
    {
      key: "seguradora",
      label: "Seguradora",
      filter: "text",
      type: "capitalize",
    },
    {
      key: "tipoSolicitacao",
      label: "Tipo de Solicitação",
      filter: "text",
      type: "capitalize",
    },
    {
      key: "parceiro",
      label: "Parceiro",
      filter: "text",
      placeholder: "Digite o parceiro",
    },
    {
      key: "premioLiquido",
      label: "Prêmio líquido ",
      filter: "number",
      type: "custom",
      response: (row) =>
        `$ ${new StringMask("#.##0,00", { reverse: true }).apply(
          row.premioLiquido?.toFixed(2).replace(".", "")
        )}`,
    },
    {
      key: "comissao",
      label: "Comissão",
      filter: "number",
      type: "custom",
      response: (row) =>
        `${new StringMask("#.##0,00", { reverse: true }).apply(
          row.comissao?.toFixed(2).replace(".", "")
        )}%`,
    },
  ];
  return (
    <Table
      cols={tableParams}
      emptyText={"Nenhuma indicação localizada!"}
      route={"reports/carteira"}
      noLink={true}
      noDelete={true}
      {...props}
    />
  );
}

export default List;
