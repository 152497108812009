import React, { useContext } from "react";
import UserProfileContext from "../../../../contexts/userProfileContext";
import { getFirstLetter } from "../../../../utils/globalFunctions";

function Topbar({ setShowUser, setASide }) {
  const profile = useContext(UserProfileContext);
  return (
    <div className="topbar d-flex justify-content-between">
      <div className="topbar-item ">
        <button
          className="btn p-0 burger-icon ml-4 d-md-block"
          id="kt_header_mobile_toggle "
          onClick={()=>setASide(prevState=>!prevState)}
          >
          <span></span>
        </button>
      </div>
      <div className="topbar-item ">
        <div
          className="btn btn-icon btn-icon-mobile w-auto btn-clean d-flex align-items-center btn-lg px-2"
          id="kt_quick_user_toggle"
          onClick={() => setShowUser((prevState) => !prevState)}>
          <span className="text-muted font-weight-bold font-size-base d-md-inline mr-1">
            Olá,
          </span>
          <span className="text-dark-50 font-weight-bolder font-size-base d-md-inline mr-3">
            {profile.nome}
          </span>
          <span className="symbol symbol-lg-35 symbol-25 symbol-success">
            <span className="symbol-label font-size-h5 font-weight-bold">
              {getFirstLetter(profile.nome)}
            </span>
          </span>
        </div>
      </div>
    </div>
  );
}

export default Topbar;
