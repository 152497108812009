import React from "react";
import Topbar from "./Topbar";
import HeaderMenu from "./HeaderMenu";

function Header({ setShowUser, setASide }) {
  return (
    <div className={`header header-fixed`} id="kt_header">
      <div
        className={`container-fluid d-flex align-items-center justify-content-between`}
      >
        <HeaderMenu />
        <Topbar setShowUser={setShowUser} setASide={setASide} />
      </div>
    </div>
  );
}

export default Header;
