import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Header from "./Header";
import Aside from "./Aside";
import Footer from "./Footer";
import Indicacao from "./Indicacao";
import Indicacoes from "./Indicacoes";
import Data from "./Profile/Data";
import QuickUser from "./QuickUser";
import UserProfileContext from "../../contexts/userProfileContext";
import UpdateListContext from "../../contexts/updateList";
import { isAuthenticated, logout } from "../../services/auth";
import api from "../../services/api";
import Password from "./Profile/Password";
import Carteira from "./Carteira";
import Comissao from "./Comissao";

const App = (props) => {
  const [userProfileData, setUserProfileData] = useState({});
  const [showUser, setShowUser] = useState(false);
  const [aSide, setASide] = useState(false);
  const [updateList, setUpdateList] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const loadUser = async () => {
      const response = await api.get("/auth/userprofile").catch((err) => err);
      if (!response.data) {
        return logout();
      }
      setUserProfileData(response.data);
      setLoading(false);
    };
    loadUser();
  }, []);
  return isAuthenticated() ? (
    loading ? null : (
      <UpdateListContext.Provider
        value={{
          updateList,
          setUpdateList,
        }}
      >
        <UserProfileContext.Provider value={userProfileData}>
          <div
            className="d-flex flex-column flex-root"
            onClick={() => {
              if (showUser) setShowUser(false);
              if (aSide) setASide(false);
            }}
          >
            <div className="d-flex flex-row flex-column-fluid page">
              <Aside
                setASide={setASide}
                aSide={aSide}
                location={props.location}
              />
              <div
                className="d-flex flex-column flex-row-fluid wrapper"
                id="kt_wrapper"
              >
                <Header setShowUser={setShowUser} setASide={setASide} />
                <div
                  id="kt_content"
                  className={`content d-flex flex-column flex-column-fluid`}
                >
                  <Switch>
                    <Route
                      path="/indicacao"
                      render={(props) => <Indicacao {...props} />}
                    />
                    <Route
                      path="/indicacoes/:page/:limit/:sort/:query"
                      render={(props) => <Indicacoes {...props} />}
                    />
                    <Route
                      path="/carteira/:page/:limit/:sort/:query"
                      render={(props) => <Carteira {...props} />}
                    />
                    <Route
                      path="/comissao/:page/:limit/:sort/:query"
                      render={(props) => <Comissao {...props} />}
                    />
                    <Route
                      path="/meus-dados"
                      render={(props) => <Data {...props} />}
                    />
                    <Route
                      path="/alterar-senha"
                      render={(props) => <Password {...props} />}
                    />
                    <Route
                      path="/"
                      render={(props) => <Indicacao {...props} />}
                    />
                  </Switch>
                </div>
                <Footer />
              </div>
            </div>
          </div>
          <QuickUser showUser={showUser} setShowUser={setShowUser} />
        </UserProfileContext.Provider>
      </UpdateListContext.Provider>
    )
  ) : (
    <Redirect to="/login" />
  );
};

export default App;
