import React, { useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import ReactLoading from "react-loading";
import api from "../../services/api";
import InputMask from "react-input-mask";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  cpfMask,
  phoneNumberMask,
  percentage,
} from "../../utils/globalFunctions";
import Switch from "@material-ui/core/Switch";

const Input = (props) => {
  const [options, setOptions] = useState([]);
  const [autocompleteLoading, setAutocompleteLoading] = useState(false);

  const defaultProps = {
    options: options,
    getOptionLabel: (option) =>
      props.paramsLabel
        ? props.paramsLabel
            .map((param) => option[param])
            .reduce((result, item) =>
              item
                ? (result || "") + (result ? " - " : "") + (item || "")
                : result || ""
            ) || null
        : null,
  };

  const autocompleteInputHandler = async (e) => {
    setOptions([]);
    setAutocompleteLoading(true);
    const data = {
      model: props.model,
      param: props.param || "",
      select: props.select || "",
      query: {},
    };
    if (
      (props.paramsGet && props.paramsLabel.length > 1) ||
      props.paramsLabel.length > 1
    ) {
      data.query.$or = props.paramsGet
        ? props.paramsGet.map((param) => ({
            [param]: { $regex: e.target.value, $options: "i" },
          }))
        : props.paramsLabel.map((param) => ({
            [param]: { $regex: e.target.value, $options: "i" },
          }));
    } else {
      data.query[
        props.paramsGet ? props.paramsGet[0] : props.paramsLabel[0]
      ] = {
        $regex: e.target.value,
        $options: "i",
      };
    }

    const response = await api.post("filter/autocomplete", data);
    setOptions(response.data);
    setAutocompleteLoading(false);
  };

  const getValue = () => {
    if (props.value !== undefined) return props.value;
    if (!props.params) return props.item;

    const params = props.params
      .split(".")
      .map((param) => (!isNaN(param) ? +param : param));
    if (params.length === 6) {
      return props.item[params[0]][params[1]][params[2]][params[3]][params[4]][
        params[5]
      ];
    }
    if (params.length === 5) {
      return props.item[params[0]][params[1]][params[2]][params[3]][params[4]];
    }
    if (params.length === 4) {
      return props.item[params[0]][params[1]][params[2]][params[3]];
    }
    if (params.length === 3) {
      return props.item[params[0]][params[1]][params[2]];
    }
    if (params.length === 2) {
      return props.item[params[0]][params[1]];
    }
    if (params.length === 1) {
      return props.item[params[0]];
    }
  };

  const inputHandler = (e) => {
    let value =
      props.type === "inputFile"
        ? {
            files: e.target.files || "",
            fileName: e.target.files[0] ? e.target.files[0].name : "",
            value: e.target.value || "",
          }
        : e.target[
            props.type === "checkbox" || props.type === "switch"
              ? "checked"
              : "value"
          ];

    if (props.capitalize) {
      value = value.toUpperCase();
    }
    if (props.mask === "cpfCNPJ") {
      value = cpfMask(value);
    } else if (props.mask === "phoneNumber") {
      value = phoneNumberMask(value);
    } else if (props.mask === "percentage") {
      value = percentage(value);
    }

    if (!props.params) return props.setItem(value);
    const copyItem = JSON.parse(JSON.stringify(props.item));
    const params = props.params
      .split(".")
      .map((param) => (!isNaN(param) ? +param : param));
    if (params.length === 6) {
      copyItem[params[0]][params[1]][params[2]][params[3]][params[4]][
        params[5]
      ] = value;
    } else if (params.length === 5) {
      copyItem.item[params[0]][params[1]][params[2]][params[3]][
        params[4]
      ] = value;
    } else if (params.length === 4) {
      copyItem[params[0]][params[1]][params[2]][params[3]] = value;
    } else if (params.length === 3) {
      copyItem[params[0]][params[1]][params[2]] = value;
    } else if (params.length === 2) {
      copyItem[params[0]][params[1]] = value;
    } else if (params.length === 1) {
      copyItem[params[0]] = value;
    }
    props.setItem(copyItem);
  };

  if (props.type === "input") {
    return (
      <div className="form-group">
        {props.label && (
          <label className="form-label text-dark">
            {props.label}
            {props.required && <span className="required">*</span>}
          </label>
        )}
        <input
          className={props.className || "form-control"}
          type={props.inputType || "text"}
          value={props.value || getValue() || ""}
          onChange={props.onChange || inputHandler}
          placeholder={props.placeholder}
          disabled={props.disabled || false}
          required={props.required || false}
        />
      </div>
    );
  }
  if (props.type === "inputOnly") {
    return (
      <input
        className={props.className || "form-control"}
        type={props.inputType || "text"}
        value={props.value || getValue() || ""}
        onChange={props.onChange || inputHandler}
        placeholder={props.placeholder}
        disabled={props.disabled || false}
        required={props.required || false}
      />
    );
  }
  if (props.type === "textarea") {
    return (
      <div className="form-group">
        {props.label && (
          <label className="form-label text-dark">
            {props.label}
            {props.required && <span className="required">*</span>}
          </label>
        )}
        <textarea
          className={props.className || "form-control"}
          value={props.value || getValue() || ""}
          onChange={props.onChange || inputHandler}
          placeholder={props.placeholder}
          disabled={props.disabled || false}
          rows={props.rows || 3}
          required={props.required || false}
        />
      </div>
    );
  }
  if (props.type === "textAreaOnly") {
    return (
      <textarea
        className={props.className || "form-control"}
        value={props.value || getValue() || ""}
        onChange={props.onChange || inputHandler}
        placeholder={props.placeholder}
        disabled={props.disabled || false}
        rows={props.rows || 3}
        required={props.required || false}
      />
    );
  }
  if (props.type === "select") {
    return (
      <div className="form-group">
        {props.label && (
          <label className="form-label text-dark">
            {props.label}
            {props.required && <span className="required">*</span>}
          </label>
        )}
        <select
          className={props.className || "form-control"}
          value={props.value || getValue() || ""}
          onChange={props.onChange || inputHandler}
          disabled={props.disabled || false}
          required={props.required || false}
        >
          {props.placeholder && <option value="">{props.placeholder}</option>}
          {props.options &&
            props.options.map((item) => (
              <option key={item.value} value={item.value}>
                {item.label || item.value}
              </option>
            ))}
        </select>
      </div>
    );
  }
  if (props.type === "selectOnly") {
    return (
      <select
        className={props.className || "form-control"}
        value={props.value || getValue() || ""}
        onChange={props.onChange || inputHandler}
        disabled={props.disabled || false}
        required={props.required || false}
      >
        {props.placeholder && <option value="">{props.placeholder}</option>}
        {props.options &&
          props.options.map((item) => (
            <option key={item.value} value={item.value}>
              {item.label || item.value}
            </option>
          ))}
      </select>
    );
  }
  if (props.type === "checkbox") {
    return (
      <label className={props.className || "kt-checkbox"}>
        <input
          type="checkbox"
          checked={props.value || getValue() || false}
          onChange={props.onChange || inputHandler}
          disabled={props.disabled || false}
        />
        <span></span>
        {props.label || null}
        
      </label>
    );
  }
  if (props.type === "radio") {
    return (
      <label className={props.className || "kt-radio"}>
        <input
          type="radio"
          value={props.value || getValue()}
          checked={getValue() === props.value || false}
          onChange={props.onChange || inputHandler}
          disabled={props.disabled || false}
        />
        {props.label || null}
        <span></span>
      </label>
    );
  }
  if (props.type === "autocomplete") {
    return (
      <div className="form-group">
        {props.label && (
          <label className="form-label text-dark">{props.label}</label>
        )}
        <Autocomplete
          {...defaultProps}
          value={props.value || getValue()}
          onChange={(e, value) => inputHandler({ target: { value } })}
          freeSolo
          noOptionsText={
            autocompleteLoading ? (
              <ReactLoading
                type="spin"
                color="#009870"
                height={30}
                width={30}
              />
            ) : (
              "Nenhum item localizado!"
            )
          }
          renderInput={(params) => (
            <TextField
              variant="outlined"
              onChange={autocompleteInputHandler}
              {...params}
              placeholder={props.placeholder}
              margin="normal"
            />
          )}
        />
      </div>
    );
  }
  if (props.type === "inputMask") {
    return (
      <div className="form-group">
        {props.label && (
          <label className="form-label text-dark">
            {props.label}
            {props.required && <span className="required">*</span>}
          </label>
        )}
        <InputMask
          className={props.className || "form-control"}
          type={props.inputType || "text"}
          value={props.value || getValue() || ""}
          onChange={props.onChange || inputHandler}
          placeholder={props.placeholder}
          disabled={props.disabled || false}
          required={props.required || false}
          mask={props.mask}
        />
      </div>
    );
  }
  if (props.type === "inputFile") {
    return (
      <div className="input-group">
        <div className="custom-file">
          <input
            type="file"
            className={props.className || "custom-file-input"}
            value={props.value || getValue().value || ""}
            onChange={props.onChange || inputHandler}
            disabled={props.disabled || false}
            required={props.required || false}
            multiple={props.multiple || false}
            accept={props.accept || "*"}
          />
          <label className="custom-file-label">
            {getValue().files[1]
              ? `${getValue().files.length} arquivos selecionados`
              : getValue().fileName || props.placeholder}
          </label>
        </div>
      </div>
    );
  }
  if (props.type === "switch") {
    return (
      <FormControlLabel
        control={
          <Switch
            className={props.className || null}
            checked={props.value || getValue()}
            onChange={props.onChange || inputHandler}
            disabled={props.disabled || false}
          />
        }
        label={props.label}
        labelPlacement={props.labelPlacement}
      />
    );
  }

  return null;
};

export default Input;
