import React, { useState } from "react";
import SubHeader from "../../SubHeader";
import Input from "../../../../components/Input";
import Swal from "sweetalert2";
import api from "../../../../services/api";

const Password = (props) => {
  const [user, setUser] = useState({
    password: "",
    newPassword: "",
  });

  const editPassword = async (e) => {
    e.preventDefault();
    const requestUser = { ...user };
    const response = await Swal.fire({
      title: "Editar Senha",
      text: "Deseja confirmar alteração da Senha",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0451e8",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, Editar Senha!",
      showLoaderOnConfirm: true,
      preConfirm: async () =>
        await api.post("auth/changepassword", requestUser).catch((err) => ({
          err: true,
          data: { message: err.response.data.message },
        })),
    });

    if (response.value) {
      Swal.fire({
        title: "Editar Senha",
        text: response.value.data.message,
        icon: response.value.err ? "error" : "success",
        preConfirm: () =>
          !response.value.err ? window.location.reload() : null,
      });
    }
  };

  return (
    <>
      <SubHeader
        title="Alterar Senha"
        icon={<i className="fas fa-user-lock"></i>}
        {...props}
      />
      <form onSubmit={editPassword}>
        <div className="mr-8 ml-8 ">
          <div className="card ">
            <div className="card-header">
              <h3 className="card-label text-dark">Alterar Senha</h3>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <Input
                    item={user}
                    setItem={setUser}
                    params="password"
                    label="Senha"
                    type="input"
                    inputType="password"
                    placeholder="Digite sua senha atual"
                    required={true}
                  />
                </div>
                <div className="col-md-6">
                  <Input
                    item={user}
                    setItem={setUser}
                    label="Nova Senha"
                    params="newPassword"
                    placeholder="Digite sua nova senha"
                    type="input"
                    inputType="password"
                    required={true}
                  />
                </div>
              </div>
            </div>
            <div className="card-footer">
              <div className="row">
                <div className="col text-center">
                  <button type="submit" className="btn btn-primary">
                    Salvar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default Password;
