import React, { useState, useEffect, useContext } from "react";
import { Modal, Dropdown } from "react-bootstrap";
import api from "../../../../services/api";
import apiOld from "../../../../services/apiOld";
import Input from "../../../../components/Input";
import ReactLoading from "react-loading";
import Swal from "sweetalert2";
import { months } from "../../../../utils/globalData";
import ProfileContext from "../../../../contexts/userProfileContext";

function ModalIndicacao(props) {
  const { _id } = useContext(ProfileContext);

  const ts = {
    cotacao: {
      label: "Cotação",
      status: {
        texto: "Realizar Contato",
        key: "realizarContato",
        icon: "fa-phone",
      },
    },
    agendamento: {
      label: "Agendamento",
      status: {
        texto: "Agendado",
        key: "agendado",
        icon: "fa-calendar",
      },
    },
  };

  const [indicacao, setIndicacao] = useState({
    nome: "",
    documento: "",
    email: "",
    telefones: [""],
    ramoSeguro: {
      nome: "",
      _id: "",
    },
    tipoSolicitacao: props.type,
    status: ts[props.type].status,
    origem: "dashboard",
  });

  const [fileUpload, setFileUpload] = useState({
    files: [],
  });

  const [loading, setLoading] = useState(true);

  const [ramos, setRamos] = useState([]);

  const [vencimento, setVencimento] = useState({
    dia: "",
    mes: "",
    ano: "",
  });

  const addTelefone = () => {
    setIndicacao((prevState) => {
      const newState = JSON.parse(JSON.stringify(prevState));
      newState.telefones.push("");
      return newState;
    });
  };

  const removeTelefone = (index) => {
    setIndicacao((prevState) => {
      const newState = JSON.parse(JSON.stringify(prevState));
      newState.telefones.splice(index, 1);
      return newState;
    });
  };

  const ramoshandler = ({ _id, nome }) => {
    setIndicacao((prevState) => {
      const newState = JSON.parse(JSON.stringify(prevState));
      newState.ramoSeguro = { _id, nome };
      return newState;
    });
  };

  const getAnos = () => {
    let m = 0;
    const anos = [];
    if (parseInt(vencimento.mes) < new Date().getMonth()) {
      m = new Date().getFullYear() + 1;
    } else {
      m = new Date().getFullYear();
    }
    for (var i = 0; i < 3; i++) {
      anos.push({ value: m + i });
    }
    return anos;
  };

  const sendIndicacao = async (e) => {
    e.preventDefault();
    const request = { ...indicacao };
    if (props.type === "agendamento") {
      request.proxAtendimento = new Date(
        +vencimento.ano,
        +vencimento.mes - 1,
        +vencimento.dia || 1
      );
    }
    const response = await Swal.fire({
      title: "Enviar Indicação",
      text: "Deseja confirmar?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0451e8",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, Salvar!",
      showLoaderOnConfirm: true,
      preConfirm: async () =>
        await api.post("indicacao", request).catch((err) => ({
          err: true,
          data: {
            message: err.response
              ? err.response.data.message
              : "Erro ao salvar indicação",
          },
        })),
    });
    if (!response.value.err && fileUpload.files[0]) {
      const config = {
        responseType: "arraybuffer",
        headers: {
          "content-type": "multipart/form-data",
          Accept: "application/pdf",
        },
      };
      for (let i = 0; i < fileUpload.files.length; i++) {
        const formData = new FormData();
        formData.append(
          "file",
          fileUpload.files[i],
          `anexo-indicacao-${response.value.data._id}-${_id}-${fileUpload.files[i].name}`
        );
        await apiOld.post("api/imagens/upload", formData, config);
      }
    }
    if (response.value) {
      await Swal.fire(
        "Enviar Indicação",
        response.value.data.message,
        response.value.err ? "error" : "success"
      );
      if (!response.value.err) {
        props.onHide();
      }
    }
  };

  useEffect(() => {
    const getRamos = async () => {
      const response = await api.get("ramos");
      setRamos(response.data);
      setLoading(false);
    };
    getRamos();
  }, []);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter "
    >
      <form onSubmit={sendIndicacao}>
        <Modal.Header closeButton>
          <Modal.Title className="contained-modal-title-vcenter text-dark">
            {ts[props.type].label}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col">
              <Input
                item={indicacao}
                setItem={setIndicacao}
                params="nome"
                capitalize={true}
                type="input"
                required={true}
                label="Nome"
                placeholder="Nome do cliente"
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Input
                item={indicacao}
                setItem={setIndicacao}
                params="documento"
                type="input"
                label="CPF/CNPJ"
                mask="cpfCNPJ"
              />
            </div>
            <div className="col">
              <Input
                item={indicacao}
                setItem={setIndicacao}
                params="email"
                type="input"
                label="Email"
                inputType="email"
              />
            </div>
          </div>
          <div className="row">
            {indicacao.telefones.map((item, index) => (
              <div className="col-6" key={index}>
                <div className="form-group">
                  <label className="text-dark">
                    Telefone / Celular<span className="required">*</span>
                  </label>
                  <div className="input-group">
                    <Input
                      item={indicacao}
                      setItem={setIndicacao}
                      params={`telefones.${index}`}
                      type="inputOnly"
                      required={true}
                      mask="phoneNumber"
                    />
                    <span className="input-group-btn">
                      {indicacao.telefones.length - 1 === index && (
                        <button
                          className="btn btn-primary"
                          type="button"
                          onClick={addTelefone}
                        >
                          <i className="fa fa-plus fa-fw"></i>
                        </button>
                      )}
                      {indicacao.telefones.length > 1 && (
                        <button
                          className="btn btn-danger ng-hide"
                          type="button"
                          onClick={() => removeTelefone(index)}
                        >
                          <i className="fa fa-trash fa-fw"></i>
                        </button>
                      )}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="row">
            <div className="col ramos">
              <div className="form-group">
                <label className="text-dark">
                  Ramo de Seguro<span className="required">*</span>
                </label>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="ramos"
                    id="dropdown-basic"
                    disabled={loading}
                  >
                    {loading ? (
                      <ReactLoading
                        type="spin"
                        color="#5aa57f"
                        height={20}
                        width={20}
                      />
                    ) : indicacao.ramoSeguro.nome ? (
                      <div className="ramos__selected-item">
                        <img
                          src={`https://ribercred.zw20.zenbytes.com.br/img/upload/icone-ramo-seguro-${indicacao.ramoSeguro._id}.png`}
                          alt={indicacao.ramoSeguro.nome}
                        ></img>{" "}
                        <span>{indicacao.ramoSeguro.nome}</span>
                      </div>
                    ) : (
                      "Selecione o ramo de seguro"
                    )}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {ramos.map((ramo) => (
                      <Dropdown.Item
                        key={ramo._id}
                        className="ramos__item"
                        onClick={() => ramoshandler(ramo)}
                      >
                        <img
                          src={`https://ribercred.zw20.zenbytes.com.br/img/upload/icone-ramo-seguro-${ramo._id}.png`}
                          alt={ramos.nome}
                        ></img>{" "}
                        <span>{ramo.nome}</span>
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
          {props.type === "agendamento" && (
            <div className="row">
              <div className="col">
                <Input
                  item={vencimento}
                  setItem={setVencimento}
                  params="dia"
                  type="input"
                  inputType="number"
                  placeholder="Dia"
                  label="Vencimento"
                />
              </div>
              <div className="col">
                <div className="form-group">
                  <label className="text-dark">&nbsp;</label>
                  <Input
                    item={vencimento}
                    setItem={setVencimento}
                    params="mes"
                    type="selectOnly"
                    required={true}
                    placeholder="Mês*"
                    options={months.map((month, index) => ({
                      value: index,
                      label: month,
                    }))}
                  />
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <label className="text-dark">&nbsp;</label>
                  <Input
                    item={vencimento}
                    setItem={setVencimento}
                    params="ano"
                    type="selectOnly"
                    required={true}
                    disabled={!vencimento.mes}
                    placeholder="Ano*"
                    options={getAnos()}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label className="text-dark">Documentos</label>
                <Input
                  type="inputFile"
                  item={fileUpload}
                  setItem={setFileUpload}
                  multiple={true}
                  accept="image/*,.pdf"
                  disabled={loading}
                  placeholder="Selecione os arquivos"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Input
                type="textarea"
                item={indicacao}
                setItem={setIndicacao}
                params="observacao"
                disabled={loading}
                label="Observação"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="submit"
            className="btn btn-primary "
            style={{ margin: "auto" }}
            disabled={loading}
          >
            <i className="far fa-paper-plane" /> Enviar Indicação
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

export default ModalIndicacao;
