import React, { useState, useEffect, useContext } from "react";
import ReactLoading from "react-loading";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import Swal from "sweetalert2";
import api from "../../services/api";
import { logout } from "../../services/auth";
import TotalItems from "../TotalItems";
import UpdateList from "../../contexts/updateList";
import { Dropdown } from "react-bootstrap";
import format from "date-fns/format";
import Filter from "../Filter";

const Table = (props) => {
  const { updateList, setUpdateList } = useContext(UpdateList);

  const [runLoadItems, setRunLoadItems] = useState(true);

  const [items, setItems] = useState([]);

  const [totalItems, setTotalItems] = useState(0);

  const [loading, setLoading] = useState(true);

  const [isError, setIsError] = useState(false);

  const [currentPath, setCurrentPath] = useState("");

  const [max, setMax] = useState(null);

  const [min, setMin] = useState(null);

  const removeItemsHandler = async () => {
    const selectItemsIds = items
      .filter((item) => item.checked)
      .map((item) => item._id);
    if (selectItemsIds[0]) {
      const result = await Swal.fire({
        text: `Deseja arquivar o${selectItemsIds[1] ? "s" : ""} itens ${
          selectItemsIds[1] ? "s" : ""
        } selecionados?`,
        title: "Arquivar Itens!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0451e8",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, arquivar itens!",
      });
      if (result.value) {
        setLoading(true);
        const response = await api.delete(props.route, {
          data: selectItemsIds,
        });
        if (response.data) {
          return setRunLoadItems(true);
        }
        setLoading(false);

        Swal.fire(
          `Arquivar Itens${selectItemsIds[1] ? "s" : ""}!`,
          `Itens${selectItemsIds[1] ? "s" : ""} arquivados${
            selectItemsIds[1] ? "s" : ""
          } com sucesso.`,
          "success"
        );
      }
    }
  };

  const pageClickHandler = (pageNumber) => {
    if (+pageNumber !== +props.match.params.page) {
      props.history.push(
        props.match.path
          .replace(":page", pageNumber)
          .replace(":limit", props.match.params.limit)
          .replace(":sort", props.match.params.sort)
          .replace(":query", props.match.params.query)
      );
      setRunLoadItems(true);
      setLoading(true);
    }
  };

  const getValue = (item, col) => {
    try {
      if (!col.params) return item;
      const params = col.params.split(".");
      if (params.length === 6) {
        return item[params[0]][params[1]][params[2]][params[3]][params[4]][
          params[5]
        ];
      }
      if (params.length === 5) {
        return item[params[0]][params[1]][params[2]][params[3]][params[4]];
      }
      if (params.length === 4) {
        return item[params[0]][params[1]][params[2]][params[3]];
      }
      if (params.length === 3) {
        return item[params[0]][params[1]][params[2]];
      }
      if (params.length === 2) {
        return item[params[0]][params[1]];
      }
      if (params.length === 1) {
        return item[params[0]];
      }
    } catch (e) {
      return "";
    }
  };

  useEffect(() => {
    const loadItems = async () => {
      setLoading(true);
      const response = await api
        .get(props.route, {
          params: { ...props.match.params },
        })
        .catch((err) => err);
      if (!response.data) {
        if (response.message === "Request failed with status code 401") {
          return logout();
        } else {
          setIsError(true);
          Swal.fire("Erro", response.message, "error");
          setLoading(false);
          return;
        }
      }
      setItems(response.data.docs);
      if (props.populateOther) {
        props.setOther(response.data);
      }
      setTotalItems(response.data.total);
      if (response.data.max && response.data.min) {
        setMax(response.data.max);
        setMin(response.data.min);
      }
      if (props.setFilter) {
        const responseFilter = await api
          .get("filters/indicacoes", {
            params: { ...props.match.params },
          })
          .catch((err) => err);
        props.setFilter({ ...responseFilter.data });
      }
      setLoading(false);
    };
    if (runLoadItems || props.match.url !== currentPath || updateList) {
      setUpdateList(false);
      setCurrentPath(props.match.url);
      setRunLoadItems(false);
      loadItems();
    }
  }, [props, runLoadItems, currentPath, updateList, setUpdateList]);

  return (
    <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded">
      <table className="kt-datatable__table table-hover">
        <thead className="kt-datatable__head">
          <tr className="kt-datatable__row text-left">
            {props.cols.map((col, index) => (
              <React.Fragment key={col.key + (col.params || "") + index}>
                <th
                  className="kt-datatable__cell kt-datatable__cell--sort"
                  style={col.width ? { width: 70 } : null}
                >
                  <Dropdown>
                    <Dropdown.Toggle
                      variant=""
                      id=""
                      className={`alias-button table-dropdown-toggle header-table ${
                        JSON.parse(props.match.params.query)[
                          col.key + (col.params ? "." + col.params : "")
                        ] ||
                        (JSON.parse(props.match.params.query).populate &&
                          JSON.parse(
                            props.match.params.query
                          ).populate.findIndex((item) => item.key === col.key) >
                            -1)
                          ? "filtered"
                          : ""
                      }`}
                    >
                      <span>{col.label}</span>
                      {col.filter ? (
                        <>
                          &nbsp;
                          {props.match.params.sort ===
                          col.key + (col.params ? "." + col.params : "") ? (
                            <i className="fa fa-sort-amount-down-alt" />
                          ) : props.match.params.sort ===
                            `-${
                              col.key + (col.params ? "." + col.params : "")
                            }` ? (
                            <i className="fa fa-sort-amount-down" />
                          ) : (
                            <i className="fa fa-sort" />
                          )}
                          <i className="fa fa-filter" />
                        </>
                      ) : null}
                    </Dropdown.Toggle>
                    {col.filter ? (
                      <Filter min={min} max={max} col={{ ...col }} {...props} />
                    ) : null}
                  </Dropdown>
                </th>
              </React.Fragment>
            ))}
            {!props.noLink ? (
              <th
                className="kt-datatable__cell kt-datatable__cell--sort"
                style={{
                  width: "120px",
                }}
              >
                <span>
                  {!props.noDelete
                    ? [
                        "Editar",
                        <i
                          key="icon"
                          className="flaticon2-trash"
                          style={{
                            fontSize: "1.2rem",
                            right: 0,
                            marginLeft: "30px",
                          }}
                          onClick={removeItemsHandler}
                        ></i>,
                      ]
                    : "Acessar"}
                </span>
              </th>
            ) : null}
          </tr>
        </thead>
        <tbody className="kt-datatable__body">
          {loading ? (
            <tr className="kt-datatable__row">
              <td className="kt-datatable__cell text-center" colSpan="30">
                <ReactLoading
                  type="spin"
                  color="#009870"
                  height={30}
                  width={30}
                />
              </td>
            </tr>
          ) : isError ? (
            <tr className="kt-datatable__row">
              <td
                className="kt-datatable__cell text-center"
                colSpan={props.cols.length + 3}
              >
                <span>Erro ao localizar items</span>
              </td>
            </tr>
          ) : items[0] ? (
            items.map((row, index) => (
              <tr className="kt-datatable__row" key={index}>
                {props.cols.map((col, indexCol) => {
                  if (col.type === "status") {
                    return (
                      <td
                        className="kt-datatable__cell"
                        key={col.key + (col.params || "") + indexCol}
                      >
                        {row[col.key] ? (
                          <span className="btn btn-bold btn-sm btn-font-sm  btn-label-success">
                            Ativo
                          </span>
                        ) : (
                          <span className="btn btn-bold btn-sm btn-font-sm  btn-label-danger">
                            Inativo
                          </span>
                        )}
                      </td>
                    );
                  } else if (col.type === "custom") {
                    return (
                      <td
                        className="kt-datatable__cell"
                        key={col.key + (col.params || "")}
                      >
                        {col.response(row)}
                      </td>
                    );
                  } else if (col.type === "date") {
                    return (
                      <td
                        className="kt-datatable__cell"
                        key={col.key + (col.params || "")}
                      >
                        {row[col.key]
                          ? format(
                              new Date(getValue(row[col.key], col)),
                              "dd/MM/yyyy"
                            )
                          : null}
                      </td>
                    );
                  } else if (col.type === "dateTime") {
                    return (
                      <td
                        className="kt-datatable__cell"
                        key={col.key + (col.params || "")}
                      >
                        {row[col.key]
                          ? format(
                              new Date(getValue(row[col.key], col)),
                              "dd/MM/yyyy hh:mm"
                            )
                          : null}
                      </td>
                    );
                  } else if (col.type === "capitalize") {
                    return (
                      <td
                        className="kt-datatable__cell"
                        key={col.key + (col.params || "")}
                      >
                        <span>
                          {getValue(row[col.key], col)
                            ? getValue(row[col.key], col).toUpperCase()
                            : null}
                        </span>
                      </td>
                    );
                  }
                  return (
                    <td
                      className="kt-datatable__cell"
                      key={col.key + (col.params || "")}
                    >
                      <span>
                        {row[col.key] ? getValue(row[col.key], col) : null}
                      </span>
                    </td>
                  );
                })}
                {!props.noLink ? (
                  <td className="kt-datatable__cell">
                    {props.editLink ? (
                      <Link
                        to={props.editLink + row._id}
                        className="btn btn-sm btn-primary"
                      >
                        <i className="flaticon-more" />
                      </Link>
                    ) : (
                      <button
                        onClick={() => props.editHandler(row)}
                        className="btn btn-sm btn-primary"
                      >
                        <i className="flaticon-more" />
                      </button>
                    )}
                  </td>
                ) : null}
              </tr>
            ))
          ) : (
            <tr className="kt-datatable__row">
              <td
                className="kt-datatable__cell text-center"
                colSpan={props.cols.length + 3}
              >
                <span>{props.emptyText}</span>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <div className="kt-datatable__pager kt-datatable--paging-loaded">
        <Pagination
          activePage={+props.match.params.page}
          itemsCountPerPage={+props.match.params.limit}
          totalItemsCount={+totalItems}
          pageRangeDisplayed={10}
          onChange={pageClickHandler}
          innerClass="kt-datatable__pager-nav"
          linkClass="kt-datatable__pager-link kt-datatable__pager-link-number"
          linkClassFirst="kt-datatable__pager-link kt-datatable__pager-link--first"
          linkClassPrev="kt-datatable__pager-link kt-datatable__pager-link--prev"
          linkClassNext="kt-datatable__pager-link kt-datatable__pager-link--next"
          linkClassLast="kt-datatable__pager-link kt-datatable__pager-link--last"
          activeLinkClass="kt-datatable__pager-link--active"
          disabledClass="kt-datatable__pager-link--disabled"
          prevPageText={<i className="flaticon2-back" />}
          firstPageText={<i className="flaticon2-fast-back" />}
          lastPageText={<i className="flaticon2-fast-next" />}
          nextPageText={<i className="flaticon2-next" />}
        />
        {!loading ? (
          <div className="kt-datatable__pager-info">
            <TotalItems {...props.match.params} total={totalItems} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Table;
