import axios from "axios";

const api = axios.create({
  baseURL: "https://ribercred.zw20.zenbytes.com.br",
  /* baseURL:
    window.location.href.indexOf("localhost") < 0
      ? "https://ribercred.zw20.zenbytes.com.br"
      : "http://localhost:3333", */
});

export default api;
