import React from "react";
import ModalIndicacao from "./ModalIndicacao";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

export function IndicacaoSeguro(props) {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6Ldr3J4fAAAAAPNIhD_p8jwwP_G2F-VVwAvk2f4T">
      <ModalIndicacao show={true} match={props.match} />
    </GoogleReCaptchaProvider>
  );
}
