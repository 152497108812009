import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import InputMask from "react-input-mask";
import Input from "../Input";
import { DateRange } from "react-date-range";
import { ptBR } from "react-date-range/dist/locale";
import endOfDay from "date-fns/endOfDay";
import startOfDay from "date-fns/startOfDay";
import Slider from "@material-ui/core/Slider";

const Filter = (props) => {
  const [input, setInput] = useState("");

  const [search, setSearch] = useState("");

  const [checklist, setChecklist] = useState([]);

  const [dateRange, setDateRange] = useState([
    {
      startDate: startOfDay(new Date()),
      endDate: startOfDay(new Date()),
      key: "selection",
    },
  ]);

  const [range, setRange] = useState([0, 0]);

  const sort = (param) => {
    props.history.push(
      props.match.path
        .replace(":page", "1")
        .replace(":limit", props.match.params.limit)
        .replace(":sort", param)
        .replace(":query", props.match.params.query)
    );
    document.getElementById("root").click();
  };

  const filterHandler = (col) => {
    const query = JSON.parse(props.match.params.query);
    if (col.filter === "text") {
      if (col.populate) {
        if (!query.populate) {
          query.populate = [];
        }
        col.populate.value = input;
        col.populate.key = col.key;
        query.populate.push(col.populate);
      } else {
        query[col.key + (col.params ? "." + col.params : "")] = {
          $regex: input,
          $options: "i",
        };
      }
    } else if (col.filter === "date") {
      query[col.key + (col.params ? "." + col.params : "")] = {
        $gte: startOfDay(dateRange[0].startDate),
        $lte: endOfDay(dateRange[0].endDate),
      };
    } else if (col.filter === "range") {
      query[col.key + (col.params ? "." + col.params : "")] = {
        $gte: range[0],
        $lte: range[1],
      };
    } else if (col.filter === "checklist") {
      query[col.filterKey] = {
        $in: props.col.checklistData
          .filter((item, index) => checklist[index])
          .map((item) => item.id),
      };
    } else {
      query[col.key + (col.params ? "." + col.params : "")] = input;
      if (input === "") {
        delete query.status;
      }
    }
    props.history.push(
      props.match.path
        .replace(":page", "1")
        .replace(":limit", props.match.params.limit)
        .replace(":sort", props.match.params.sort)
        .replace(":query", JSON.stringify(query))
    );
    setInput("");
    document.getElementById("root").click();
  };

  const getFilterType = () => {
    return (
      <div className="input-filter">
        {props.col.filter === "text" || props.col.filter === "number" ? (
          <div className="input-group mb-1 pl-2">
            {props.col.mask ? (
              <InputMask
                mask={props.col.mask}
                type="text"
                className="form-control form-control-sm"
                placeholder={props.col.placeholder}
                value={input}
                onChange={(e) => setInput(e.target.value)}
              />
            ) : (
              <Input
                type="inputOnly"
                inputType={props.col.filter}
                className="form-control form-control-sm"
                placeholder={props.col.placeholder}
                item={input}
                setItem={setInput}
              />
            )}
            <div className="input-group-append">
              <button
                className="btn btn-primary btn-sm"
                type="submit"
                disabled={!input}>
                <i className="fa fa-filter" />
              </button>
            </div>
          </div>
        ) : props.col.filter === "status" ? (
          <div className="input-group mb-1 pl-2">
            <Input
              type="selectOnly"
              className="form-control form-control-sm"
              placeholder="Todos"
              item={input}
              setItem={setInput}
              options={[
                { value: "true", label: "Ativo" },
                { value: "false", label: "Inativo" },
              ]}
            />
            <div className="input-group-append">
              <button className="btn btn-primary btn-sm" type="submit">
                <i className="fa fa-filter" />
              </button>
            </div>
          </div>
        ) : props.col.filter === "select" ? (
          <div className="input-group mb-1 pl-2">
            <Input
              type="selectOnly"
              className="form-control form-control-sm"
              placeholder="Todos"
              item={input}
              setItem={setInput}
              options={props.col.options.map((item) => ({
                value: item.value,
                label: item.label || item.value,
              }))}
            />
            <div className="input-group-append">
              <button className="btn btn-primary btn-sm" type="submit">
                <i className="fa fa-filter" />
              </button>
            </div>
          </div>
        ) : props.col.filter === "date" ? (
          <>
            <DateRange
              editableDateInputs={true}
              onChange={(item) => setDateRange([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={dateRange}
              locale={ptBR}
              endDatePlaceholder="Até"
              startDatePlaceholder="De"
              dateDisplayFormat="dd/MM/yyyy"
              color="#009870"
              rangeColors={["#009870"]}
            />
            <button className="btn btn-primary btn-sm btn-block" type="submit">
              Filtrar <i className="fa fa-filter" />
            </button>
          </>
        ) : props.col.filter === "range" ? (
          <>
            <Slider
              value={range}
              onChange={(e, newValue) => setRange(newValue)}
              valueLabelDisplay="auto"
              aria-labelledby="range-slider"
              min={
                props.min
                  ? props.min[
                      props.col.key +
                        (props.col.params ? "." + props.col.params : "")
                    ]
                  : null
              }
              max={
                props.max
                  ? props.max[
                      props.col.key +
                        (props.col.params ? "." + props.col.params : "")
                    ]
                  : null
              }
            />
            <div className="input-group mb-3">
              <Input
                type="inputOnly"
                className="form-control form-control-sm"
                placeholder="De"
                item={range}
                params="0"
                setItem={setRange}
              />
              <Input
                type="inputOnly"
                className="form-control form-control-sm"
                placeholder="Até"
                item={range}
                params="1"
                setItem={setRange}
              />
            </div>
            <button className="btn btn-primary btn-sm btn-block" type="submit">
              Filtrar <i className="fa fa-filter" />
            </button>
          </>
        ) : props.col.filter === "checklist" ? (
          <>
            <div className="d-flex flex-column checklist">
              <Input
                type="inputOnly"
                className="form-control form-control-sm checklist-search"
                placeholder="Localizar"
                item={search}
                setItem={setSearch}
              />
              {!!search && (
                <i
                  className="fas fa-times checklist-close"
                  onClick={() => setSearch("")}
                />
              )}
              {props.col.checklistData.map((item, index) => (
                <div
                  className={`d-flex checklist-filter align-items-start ${filterChecklist(
                    item.id
                  )}`}
                  key={item.id}>
                  <Input
                    type="checkbox"
                    label={item.label}
                    className="kt-checkbox"
                    params={index.toString()}
                    item={checklist}
                    setItem={setChecklist}
                  />
                
                </div>
              ))}
            </div>
            <button className="btn btn-primary btn-sm btn-block" type="submit">
              Filtrar <i className="fa fa-filter" />
            </button>
          </>
        ) : null}
      </div>
    );
  };

  const filterChecklist = (id) => {
    if (!search) {
      return "";
    }
    const checklistFiltered = props.col.checklistData
      .filter(
        (item) => item.label.toLowerCase().indexOf(search.toLowerCase()) > -1
      )
      .map((item) => item.id);
    return checklistFiltered.indexOf(id) > -1 ? "" : "display-none";
  };

  useEffect(() => {
    if (props.col.checklistData) {
      setChecklist(props.col.checklistData.map((item) => false));
    }
  }, [props.col.checklistData]);

  return (
    <Dropdown.Menu className="dropdown-menu-fit dropdown-menu dropdown-filter">
      <ul className="kt-nav">
        <li className="kt-nav__section kt-nav__section--first">
          <span className="kt-nav__section-text">Ordenar</span>
        </li>
        <li className="kt-nav__item">
          <button
            type="button"
            className="kt-nav__link alias-button btn-block text-left"
            onClick={() =>
              sort(
                props.col.key + (props.col.params ? "." + props.col.params : "")
              )
            }>
            <span className="kt-nav__link-text">Crescente</span>
            <i className="kt-nav__link-icon fa fa-sort-amount-down-alt"></i>
          </button>
        </li>
        <li className="kt-nav__item">
          <button
            type="button"
            className="kt-nav__link alias-button  btn-block text-left"
            onClick={() =>
              sort(
                `-${
                  props.col.key +
                  (props.col.params ? "." + props.col.params : "")
                }`
              )
            }>
            <span className="kt-nav__link-text">Descrescente</span>
            <i className="kt-nav__link-icon fa fa-sort-amount-down"></i>
          </button>
        </li>
        <li className="kt-nav__section">
          <span className="kt-nav__section-text">Filtro</span>
        </li>
        <li className="kt-nav__item">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              filterHandler(props.col);
            }}>
            {getFilterType()}
          </form>
        </li>
      </ul>
    </Dropdown.Menu>
  );
};

export default Filter;
