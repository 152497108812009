import React, { useState } from "react";
import SubHeader from "../SubHeader";
import ModalIndicacao from "./ModalIndicacao";

function Indicacao(props) {
  const [modalShow, setModalShow] = useState(false);
  const [indicacaoType, setIndicacaoType] = useState("");
  const modalHandler = (type) => {
    setIndicacaoType(type);
    setModalShow(true);
  };
  return (
    <>
      {modalShow ? (
        <ModalIndicacao
          show={modalShow}
          onHide={() => setModalShow(false)}
          location={props.location}
          type={indicacaoType}
        />
      ) : null}
      <SubHeader
        title="Indicação"
        icon={<i className="far fa-hand-point-right"></i>}
        {...props}
      />
      <div className="mr-8 ml-8">
        <div className="card ">
          <div className="card-body">
            <div className="row">
              <div className="col-md-6 mb-5">
                <div className="indicacao">
                  <div>
                    <img
                      src="https://zenbytes-public.sfo2.digitaloceanspaces.com/ribercred/titulo_cotacao.png"
                      className="img-fluid centered"
                      alt="cotacao"
                    />
                    <ul>
                      <li>
                        Para clientes que queiram contratar um novo seguro agora
                      </li>
                      <li>
                        Também para cliente que já tenham seguro e está no
                        período de renovar
                      </li>
                      <li>
                        Entramos em contato com o cliente em até 5 minutos
                      </li>
                    </ul>
                  </div>
                  <div className="text-center div-ind-btn pb-5">
                    <button
                      className="btn btn-lg btn-primary btn-indicacao"
                      onClick={() => modalHandler("cotacao")}
                    >
                      <strong>SOLICITAR</strong>
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-5">
                <div className="indicacao">
                  <div>
                    <img
                      src="https://zenbytes-public.sfo2.digitaloceanspaces.com/ribercred/titulo_agendamento.png"
                      className="img-fluid centered"
                      alt=" agendamento"
                    />
                    <ul>
                      <li>
                        Para clientes que tenham seguro, mas não estão no
                        período de renovar
                      </li>
                      <li>
                        Clientes que tenham feito a renovação recentemente ou
                        fará nos próximos meses
                      </li>
                      <li>
                        Fazemos um agendamento em nosso sistema e entraremos em
                        contato com o cliente próximo à data de renovação
                      </li>
                    </ul>
                  </div>
                  <div className="text-center div-ind-btn pb-5">
                    <button
                      className="btn btn-lg btn-primary btn-indicacao"
                      onClick={() => modalHandler("agendamento")}
                    >
                      <strong>SOLICITAR</strong>
                    </button>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-4 mb-5">
                <div className="indicacao">
                  <div>
                    <img
                      src="https://zenbytes-public.sfo2.digitaloceanspaces.com/ribercred/titulo_endosso.png"
                      className="img-fluid centered"
                      alt="endosso"
                    />
                    <ul>
                      <li>
                        No caso da pessoa já ser nosso cliente e solicitou fazer
                        alterações na apólice
                      </li>
                      <li>
                        Alterações como: troca de veículo, alterações do perfil,
                        acrescentar ou remover membros, alterações no endereço,
                        etc.
                      </li>
                    </ul>
                  </div>
                  <div className="text-center div-ind-btn pb-5">
                    <button
                      className="btn btn-lg btn-primary btn-indicacao"
                      onClick={() => modalHandler("endosso")}>
                      <strong>SOLICITAR</strong>
                    </button>
                  </div>
                </div>
              </div>
             */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Indicacao;
