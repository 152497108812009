import React from "react";

function HeaderMenu() {
  return (
    <div className="header-menu-wrapper header-menu-wrapper-left">
      <div className="header-menu header-menu-mobile header-menu-layout-default"></div>
    </div>
  );
}

export default HeaderMenu;
