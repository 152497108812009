import React from "react";
import Table from "../../../../components/Table";
import StringMask from "string-mask";

function List(props) {
  const tableParams = [
    {
      key: "data_entrada_operacao",
      label: "Data Entrada",
      filter: "date",
      type: "date",
    },
    {
      key: "nome_loja",
      label: "Parceiro",
      filter: "text",
      type: "capitalize",
    },
    {
      key: "nome_cliente",
      label: "Nome do Cliente",
      filter: "text",
      type: "capitalize",
    },
    {
      key: "cpf_cnpj",
      label: "CPF-CNPJ",
      filter: "text",
    },
    {
      key: "convenio",
      label: "Ramo de seguro",
      filter: "text",
      type: "capitalize",
    },
    {
      key: "nome_instituicao",
      label: "Seguradora",
      filter: "text",
      type: "capitalize",
    },
    {
      key: "tipo_operacao",
      label: "Tipo de Operação",
      filter: "text",
      type: "capitalize",
    },
    {
      key: "base_calculo",
      label: "Prêmio ",
      filter: "number",
      type: "custom",
      response: (row) =>
        `R$ ${new StringMask("#.##0,00", { reverse: true }).apply(
          row.base_calculo
            ? `${row.base_calculo.toFixed(2)}`.replace(".", "")
            : "0"
        )}`,
    },
    {
      key: "valor_pago_comissao",
      label: "Comissão",
      filter: "number",
      type: "custom",
      response: (row) =>
        `R$ ${new StringMask("#.##0,00", { reverse: true }).apply(
          row.valor_pago_comissao
            ? `${row.valor_pago_comissao.toFixed(2)}`.replace(".", "")
            : "0"
        )}`,
    },
    {
      key: "data_pgto_comissao",
      label: "Data Pagamento",
      filter: "date",
      type: "date",
    },
  ];
  return (
    <Table
      cols={tableParams}
      emptyText={"Nenhuma indicação localizada!"}
      route={"reports/comissao"}
      noLink={true}
      {...props}
    />
  );
}

export default List;
